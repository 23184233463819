import { Router } from '@reach/router'
import { Slot, StartlibsProvider, useToggle } from '@startlibs/core'
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { customTheme } from '@startlibs/utils';
import { hot } from 'react-hot-loader/root'
import React, { useEffect, useState } from 'react'
import { CheckScrollOnBottom } from './components/CheckScrollOnBottom'
import { Button, Dialog} from '@startlibs/components'
import { PageLoadingSuspense } from './components/PageLoading'
import { ProviderErrorBoundary } from './pages/errors/ProviderError'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'
import { MainPage } from './pages/MainPage';
import { Notifications } from './components/Notifications';
import { MultiFactorAuthPage } from './pages/MultiFactorAuthPage';
import { MultiFactorAuthPageSpa } from './pages/MultiFactorAuthPageSpa';

const Identity = React.forwardRef(({ children }, ref) => <React.Fragment>{children}
  <div ref={ref} />
</React.Fragment>)

const DefaultStyle = createGlobalStyle`
  ${customTheme('DefaultStyle')};
`

let authenticated = process.env.NODE_ENV !== 'development'
const App = () => {

  const errorDialog = useToggle()
  const tokenExpiredDialog = useToggle()
  

  const [instanceData, setInstanceData] = useState()
  const [loadedData, setLoadedData] = useState(false)

  useEffect(() => {
    
    try{
      const urlParams = new URLSearchParams(window.location.search)
      const data = urlParams.get("data") || urlParams.get("_c")
      if(data) {
        let ase64decoded = atob(data)
        let json = JSON.parse(ase64decoded)
        setInstanceData(json)
        window.localStorage.setItem("instanceData", JSON.stringify(json))
        // remove url params
        window.history.replaceState(null, null, window.location.pathname);
        setLoadedData(true)
      }else{
        // navigate to origin url will bring the _c/data param via gateway. 
        // This enforces the new data from the gateway.
        // This is helpful in a scenario where the logo has been changed,
        // and if the user has already data in the local storage that would not be refreshed.
        window.location.href = window.location.origin;

        // const instanceDataFromLocalStorage = window.localStorage.getItem("instanceData")
        // if(instanceDataFromLocalStorage) {
        //   setInstanceData(JSON.parse(instanceDataFromLocalStorage))
        //   setLoadedData(true)
        // }else{
        //   // navigate to origin url will bring the _c/data param via gateway. 
        //   // This prevents login attempts with wrong data, when directly navigating to /spa/login (without _c/data param)
        //   window.location.href = window.location.origin;
        // }
      }  
    }catch(e){
      console.log(e)
      setLoadedData(true)
    }

  }, [])

  return <ThemeProvider theme={StyledTheme}>
    <StartlibsProvider value={StartlibsConfig}>
      <>
        <DefaultStyle />
        <CheckScrollOnBottom />
        <ProviderErrorBoundary>
          <PageLoadingSuspense>
            <Router component={Identity}>
              <MainPage
                default
                instanceData={instanceData}
                loadedData={loadedData}
              />
              <MultiFactorAuthPage
                path="/mfa"
                instanceData={instanceData}
                loadedData={loadedData}
              />

              <MultiFactorAuthPageSpa
                path="/spa/mfa"
                instanceData={instanceData}
                loadedData={loadedData}
              />
            </Router>
            {
              tokenExpiredDialog.isOpen &&
                <Dialog title="Your session has expired">
                      <p>Please refresh or reopen this page to proceed.</p>
                      <Button highlight onClick={()=>{document.location.reload(true);}}>Refresh</Button>
                </Dialog>
            }
            {/* {
              errorDialog.isOpen && 
                <FetchErrorDialog closeDialog={errorDialog.close} item={errorItem} />
            } */}
          </PageLoadingSuspense>
        </ProviderErrorBoundary>
        <Notifications />
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100 }}>
          <Slot name="Popup" />
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200 }}>
          <Slot name="Portal" />
          <Slot name="Dialog" />
          <Slot name="Confirm-Dialog" />
          <Slot name="Notification" />
          <Slot name="Notifications" />
        </div>
        <div id="version" style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <span style={{display: 'none'}}>Running version: {process.env.V_TAG}</span>
        </div>   
      </>
    </StartlibsProvider>
  </ThemeProvider>
}

export default hot(App)
