import {
  isMobile,
  _s,
  getColor,
  postFetcherWithResponse,
} from '@startlibs/utils'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp';
import styled, { css } from 'styled-components'
import { PurviewFooter } from '../components/PurviewFooter'
import { stickyOnDesktop } from '../utils/stickyOnDesktop';
import { CardHeader, SignInCard, SignInLayout, SystemLogo } from '../components/SigninLayout';
import { Errors, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../utils/validation';
import { Button, Icon, Loading } from '@startlibs/components';
import { navigate } from '@reach/router';
import { getRefreshToken, setRefreshToken } from '../hooks/useRefresh';
import { EXPIRED_TOKEN_TRY_REFRESH } from './MainPage';
import { setNotification } from '../components/Notifications';

export const ACCESS_ERROR = 'Could not verify code'

const DEFAULT_VALUES = { code: '' }

const preValidation = buildValidation({ code: [required] })

const FooterContainer = styled.div`
  ${props => !props.isMobile && css`
    min-width: ${props => props.visibleColumnsAmount > 8 ? '115rem' : '100rem'};
  `}
`
const UserlistPurviewFooter = styled(PurviewFooter)`
  ${stickyOnDesktop()}
  margin-left: 0;
  margin-right: 0;
`
export const PasswordVisibilityButton = styled(Icon)`
  position: absolute;
  right: 1rem;
  bottom: 5px;
  font-size: 24px;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
  :hover {
    color: rgba(0,0,0,0.75);
  }
`

const NeedSupport = styled.div`
  max-width: 36rem;
  margin: 18px auto;
  text-align: center;
  font-size: 13px;
`

const Title = styled.h3`
  margin-bottom: 0.25rem;
  color: ${getColor('main')};
  font-size: 22px;
  font-weight: 800;
`

const ActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

const BlueLink = styled.a`
  color: ${getColor('main')};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`

// Reusable function to handle token refresh and retry logic
const fetchWithTokenRefresh = (fetchFunction, url, data, options, { jwt, setJwt, setRefreshToken }) => {
  const doFetch = (token) => fetchFunction(url, data, { ...options, headers: { 'Authorization': `Bearer ${token}`, ...options.headers } });

  return doFetch(jwt).catch(err => {
    if (err && err.length > 1) {
      const status = err[1]?.status || 0;
      const messageCode = err[0]?.message;
      if (status === 403 && messageCode === EXPIRED_TOKEN_TRY_REFRESH) {
        // Token expired, refresh token
        return postFetcherWithResponse('/gateway/refresh', {}, {
          headers: {
            'Authorization': 'Bearer ' + getRefreshToken(),
            'X-Auth-Token': jwt,
          }
        }).then(([data, res]) => {
          const authToken = res.headers.get('X-Auth-Token');
          setJwt(authToken);
          const refreshToken = res.headers.get('X-Refresh-Token');
          setRefreshToken(refreshToken);
          // Retry the original request with new token
          return doFetch(authToken);
        }).catch(refreshErr => {
          const refreshStatus = refreshErr[1]?.status || 0;
          if (refreshStatus === 403) {
            setNotification({ type: 'alert', msg: 'Your session has expired. Please login again.' });
            navigate('/');
          }
          throw refreshErr;
        });
      }
    }
    throw err;
  });
}

export const MultiFactorAuthPage = ({ loadedData, instanceData }) => {

  const defaultLogoURL = '/spa/public/purview-logo.png'
  const [jwt, setJwt] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingResend, setIsLoadingResend] = useState(false)
  const formref = useRef()

  const title = instanceData?.title || 'Purview Image - Login'
  const systemId = instanceData?.pasSystemId || 'image'
  const phone = instanceData?.supportPhone || '(800) 501-1537'
  const email = instanceData?.supportMail || 'support@purview.net'

  const DEFAULT_VALUES = { username: '', systemId: systemId }

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const data = urlParams.get("jwt")
      if (data) {
        setJwt(data)
        // remove url params
        window.history.replaceState(null, null, window.location.pathname);
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  return <>
    <SignInLayout>
      <SignInCard>
        <CardHeader>
          {loadedData && <SystemLogo><div><img src={instanceData?.logo || defaultLogoURL} alt="Logo" /></div></SystemLogo>}
        </CardHeader>
        <WithForm
          ref={formref}
          alwaysSave
          preValidation={preValidation}
          values={DEFAULT_VALUES}
          action={(values) => {
            formref.current.clearErrors()
            setIsLoading(true)
            fetchWithTokenRefresh(
              postFetcherWithResponse,
              '/gateway/mfa',
              values.code,
              {},
              { jwt, setJwt, setRefreshToken }
            )
              .then(([data, res]) => {
                setIsLoading(false)
                if (data && data.redirectUrl) {
                  window.location.href = data.redirectUrl
                }
              })
              .catch(err => {
                setIsLoading(false)
                if (err && err.length > 1) {
                  const status = err[1]?.status || 0
                  if (status === 401) {
                    // Invalid code
                    formref.current.setErrors({ code: 'Access denied.' })
                  } else if (status === 400) {
                    // Invalid code
                    formref.current.setErrors({ code: 'Invalid code. Please try again.' })
                  } else if (status === 500) {
                    // server error
                    formref.current.setErrors({ title: 'Server error. Please try again.' })
                  }
                }
              })
          }}
        >{form => <>
          <Title>Two-Factor Authentication</Title>
          <p style={{ color: 'grey', fontSize: '14px', marginBottom: '1rem' }}>A temporary code was just sent to your email.</p>
          <TextInput
            autoFocus
            name="code"
            autoComplete="off"
            label="Code"
            placeholder="Enter the code here"
            path="code"
          />
          <p style={{ color: 'grey', fontSize: '14px', marginBottom: '0.25rem' }}>Did not get the email? <BlueLink disabled={isLoadingResend}
            onClick={() => {
              setIsLoadingResend(true)
              fetchWithTokenRefresh(
                postFetcherWithResponse,
                '/gateway/resend-mfa',
                {},
                {},
                { jwt, setJwt, setRefreshToken }
              )
                .then(([data, res]) => {
                  setNotification({ type: 'success', msg: 'The verification code has been sent to your email address' })
                  setIsLoadingResend(false)
                })
                .catch(err => {
                  setIsLoadingResend(false)
                  if (err && err.length > 1) {
                    const status = err[1]?.status || 0
                    if (status === 500) {
                      setNotification({ type: 'alert', msg: 'Server error. Please try again later.' })
                    }
                  }
                })
            }}>Resend it</BlueLink>{isLoadingResend ? <Loading size={16} borderWidth={3} style={{ marginLeft: '0.25rem', marginRight: '0.25rem', display: 'inline-block' }} /> : '.'}</p>
          <p style={{ color: 'grey', fontSize: '12px' }}>Please also check your spam or junk folder if necessary.</p>
          <Errors />
          <ActionsDiv>
            <Button onClick={() => navigate('/')} type="button">Cancel</Button>
            <Button highlight isLoading={isLoading} type="submit">Next</Button>
          </ActionsDiv>
        </>}</WithForm>
      </SignInCard>
      <NeedSupport>
        <b>Need support? Call {phone} or E-mail <a href={`mailto:${email}`}>{email}</a></b><p></p>
      </NeedSupport>
    </SignInLayout>
    <FooterContainer
      isMobile={isMobile()}
    >
      <UserlistPurviewFooter />
    </FooterContainer>
  </>
}
