
let resolveRefreshToken = null
let refreshToken = new Promise((res) => resolveRefreshToken = res)

export const setRefreshToken = (newRefreshToken, sessionKey) => {
  refreshToken = newRefreshToken
  resolveRefreshToken(refreshToken)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey + "-refresh", newRefreshToken)
  }
}
export const getRefreshToken = () => refreshToken