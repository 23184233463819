import {Button} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils'
import React from 'react'
import styled, {css} from 'styled-components'

const SignInPageWrapper = styled.div`
  min-height: 100vh;
  padding-bottom: 100px;
  @supports (flex-wrap: wrap) {
    display: flex;
  }

  /* Mobile responsiveness */
  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding-bottom: 50px;   /* Adjust padding for mobile */
    justify-content: flex-start; /* Align content at the top */
  }
`

const SignInPageContent = styled.div`
  margin: auto;
  padding: 3rem 1rem 0;
  max-width: 46rem;
  width: 100%;

  /* Mobile responsiveness */
  @media (max-width: 768px) {
    padding: 2rem 1rem 0; /* Reduce padding on mobile */
    max-width: 90%;       /* Adjust max width to fit mobile screens */
  }

  .password-link {
    float: right;
    vertical-align: bottom;
    color: rgba(0,0,0,0.5);
    line-height: 19px;
    &.has-error {
      color: ${getColor('alert')};
    }
  }
`

export const SystemLogo = styled.div`
  margin: 0 auto 3rem;
  text-align: center;
  div {
    position: relative;
    display: inline-block;
    :before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: rgba(0,0,0,0.05);
    }
  }
  img {
    background: white;
    max-width: 360px;
    max-height: 180px;
    margin: 0 auto;
    ${media.max(500)`
      max-width: 100%;
    `}
    ${media.max(400)`
      max-width: 100%;
    `}
    ${media.max(340)`
      max-width: 100%;
    `}
  }
`

export const SignInCard = styled.div`
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 36rem;
  margin: 0 auto;

   /* Mobile responsiveness */
  @media (max-width: 768px) {
    padding: 2rem;
    max-width: 90%; /* Adjust width for mobile */
  }

  p {
    font-size: 14px;
  }
  ${props => props.centralizedContent && `
    text-align: center;
  `}
`

export const CardActionButton = styled(Button)`
  min-width: 12rem;
  margin: 2rem auto 0;
  display: block
`

export const CardHeader = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;
  h1 {
    color: ${getColor('main')};
    font-size: 22px;
    margin-bottom: 0.75rem;
    text-align: center;
  }
  p {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
  }
`

export const AdditionalInfo = styled.div`
  font-size: 12px;
  color: rgba(0,0,0,0.5);
`

export const AdditionalActionButtons = styled.div`
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 36rem;
  padding: 1rem;
  background-color: rgba(0,0,0,0.05);
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  
  ${Button} {
    flex-shrink: 0;
  }
  p {
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    max-width: 20rem;
  }
  /* Mobile responsiveness */
  ${media.max(768)`
    text-align: center;
    p {
      margin-bottom: 1rem;
    }
  `}
  @media print {
    p {
      display: inline-block;
      width: 20rem;
    }
    ${Button} {
      display: inline-block;
    }
  }
`

export const ShareBottomBox = styled(AdditionalActionButtons)`
  max-width: 40rem;
  margin: -4rem 1rem 2rem;
  @-moz-document url-prefix() {
    margin-top: -5rem;
  }
  ${media.max(500)`
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @-moz-document url-prefix() {
      margin-top: -6.35rem;
    }
    p, ${Button} {
      flex-basis: 240px;
      text-align: center;
    }
    p {
      margin-bottom: 1rem;
    }
  `}

  /* IE11 Adjustments */
  @media all and (-ms-high-contrast:none) {

    & { margin-left: auto; margin-right: auto; } /* IE10 */
     *::-ms-backdrop, & { margin-left: auto; margin-right: auto; } /* IE11 */

   }
`

export const FieldRequirements = styled.div`
  background: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.5);
  font-size: 11px;
  padding: 0.75rem;
  border-radius: 5px;
  ${props => props.hasErrors && css`
    background: #ffdedf;
    color: ${getColor('alert')};
  `}
`

export const BelowFieldDescription = styled.div`
  color: rgba(0,0,0,0.5);
  font-size: 11px;
  margin-bottom: 1rem;
  margin-top: -0.5rem;z
`

export const SignInMessageBox = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid ${props => props.warning ? '#b3880d' : getColor('main')};
  background: ${props => props.warning ? '#ffe18b' : '#bae5f7'};
  color: ${props => props.warning ? '#906c00' : 'inherit'};
  font-size: 14px;
  max-width: 35rem;
  margin: auto;
  margin-bottom: 1rem;
`

export const SignInLayout = ({className, children}) => {
  // const logoURL = '/public/purview-logo.png'
  return (
    <SignInPageWrapper className={className}>
      <SignInPageContent>
        {/* <SystemLogo><div><img src={logoURL} alt="Logo"/></div></SystemLogo> */}
        {children}
      </SignInPageContent>
    </SignInPageWrapper>
  )
}
