import {
  isMobile,
  _s,
  postFetcherWithResponse
} from '@startlibs/utils'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp';
import styled, { css } from 'styled-components'
import { PurviewFooter } from '../components/PurviewFooter'
import { stickyOnDesktop } from '../utils/stickyOnDesktop';
import { CardActionButton, CardHeader, SignInCard, SignInLayout, SystemLogo } from '../components/SigninLayout';
import { Errors, TextInput,  WithForm } from '@startlibs/form';
import { buildValidation, minLength, required } from '../utils/validation';
import { Icon } from '@startlibs/components';
import { navigate } from '@reach/router';
import { setRefreshToken } from '../hooks/useRefresh';

export const ACCESS_ERROR = 'Not found'
export const NOT_AUTHORIZED = 'Login failed, please try again'
export const EXPIRED_TOKEN_TRY_REFRESH = 'error.403'

const preValidation = buildValidation({ username: [required], password: [required, minLength(8)] })

const FooterContainer = styled.div`
  ${props => !props.isMobile && css`
    min-width: ${props => props.visibleColumnsAmount > 8 ? '115rem' : '100rem'};
  `}
`
const UserlistPurviewFooter = styled(PurviewFooter)`
  ${stickyOnDesktop()}
  margin-left: 0;
  margin-right: 0;
`

export const PasswordVisibilityButton = styled(Icon)`
  position: absolute;
  right: 1rem;
  bottom: 5px;
  font-size: 24px;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
  :hover {
    color: rgba(0,0,0,0.75);
  }
`

const Container = styled.div`position:relative;`

const NeedSupport = styled.div`
  max-width: 36rem;
  margin: 18px auto;
  text-align: center;
  font-size: 13px;
`

export const MainPage = ({loadedData, instanceData}) => {

  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formref = useRef()
  // const defaultLogoURL = '/public/Purview_Logo_Horizontal_Main.png'
  const defaultLogoURL = '/spa/public/purview-logo.png'

  const title = instanceData?.title || 'Purview Image - Login'
  const systemId = instanceData?.systemId || 'image'
  const phone = instanceData?.supportPhone || '(800) 501-1537'
  const email = instanceData?.supportMail || 'support@purview.net'

  const DEFAULT_VALUES = { username: ''}


  const ERROR_401_LEGACY = 'error.401.legacy'
  const INVALID_PASSWORD_REDIRECT_TO_PAS = "error.http.401"
  const SUSPENDED = "error.suspended"
  const ERROR_401_NOT_ALLOWED = "error.401.not.allowed"
  const ACCESS_DENIED = "error.401.access.denied"
  const NOT_ACTIVATED = "error.notActivated"
  const LOCKED = "error.locked"
  
  useEffect(() => {
    document.title = title
  }, [title])
  
  return <>
    <SignInLayout>
      <SignInCard>
        <CardHeader>
          {loadedData && <SystemLogo><div><img src={instanceData?.logo || defaultLogoURL} alt="Logo"/></div></SystemLogo>}
        </CardHeader>
        <WithForm
          ref={formref}
          alwaysSave
          preValidation={preValidation}
          values={DEFAULT_VALUES}
          action={(values) => {
            formref.current.clearErrors()
            setIsLoading(true)
            // TODO: /gateway/login on prod. Only /login when ngrok
            postFetcherWithResponse('/gateway/login',{
              username: values.username,
              password: values.password,
              systemId: systemId 
            }).then(([data, res]) => {
              
              // To get a specific header value
              const jwt  = res.headers.get('X-Auth-Token');
              const refreshToken = res.headers.get('X-Refresh-Token');
              setRefreshToken(refreshToken)
              setIsLoading(false)
              
              // Go direct to worklist
              if(data && data.redirectUrl){
                window.location.href = data.redirectUrl
                return
              }
              
              // MFA code page // change to mfa
              navigate(`/mfa?jwt=${jwt}`)
              
            })
            .catch(err => {
              setIsLoading(false)
              if(err && err?.length > 1){
                // console.log(err[0], err[1])
                let status = err[1]?.status || 0
                if(status === 401){
                  let errorMessage = err[0]?.message || ""

                  if(errorMessage === ERROR_401_LEGACY){
                    formref.current.setErrors({title: 'Username or password is incorrect. Please try again.'}) 
                  }

                  if(errorMessage === INVALID_PASSWORD_REDIRECT_TO_PAS){
                    // redirect pas
                    let pasUrl = err[0]?.redirectUrl
                    if(pasUrl){
                      window.location.href = pasUrl
                    }
                    formref.current.setErrors({title: 'Username or password is incorrect. Please try again.'})  
                    return
                  }

                  if(errorMessage === ERROR_401_NOT_ALLOWED){
                    // redirect pas
                    let pasUrl = err[0]?.redirectUrl
                    if(pasUrl){
                      window.location.href = pasUrl
                    }
                    formref.current.setErrors({title: 'This URL is not allowed.'})  
                    return
                  }

                  if(errorMessage === SUSPENDED){                    
                    let displayMessage = 'This account has been suspended. Please contact your administrator.'
                    formref.current.setErrors({title: displayMessage})
                    return
                  }

                  if(errorMessage === ACCESS_DENIED){                    
                    let displayMessage = 'Access denied. Please contact your administrator.'
                    formref.current.setErrors({title: displayMessage})
                    return
                  }

                  if(errorMessage === NOT_ACTIVATED){
                    let email = err[0]?.params?.email
                    let displayMessage = <>
                      <div><b>Account not activated yet.</b> We just sent a new activation link to <b>{email}</b>.</div>
                      <span>Please check your spam folder if necessary.</span>
                    </>
                    formref.current.setErrors({title: displayMessage})
                    return
                  }

                  if(errorMessage === LOCKED){
                    let email = err[0]?.params?.email
                    let displayMessage = <>This account has been locked. Please check <b>{email}</b> and click the link to unlock.</>
                    formref.current.setErrors({title: displayMessage})
                    return
                  }

                }
                if(status === 404 || status === 400){
                  // not found
                  formref.current.setErrors({title: 'Username or password is incorrect. Please try again.'})
                }
                if(status === 500){
                  // server error
                  formref.current.setErrors({title: 'Server error. Please try again.'})
                }
              }
            })
          }}
        >{form => <>
          <TextInput
            autoFocus
            name="txtUserId"
            autoComplete="txtUserId"
            label="Username"
            placeholder=""
            path="username"
          />
          <Container>
            <TextInput
              name="txtPassword"
              autoComplete="txtPassword"
              label="Password"
              path="password"
              type={showPassword ? 'text' : "password"}
            />
            <PasswordVisibilityButton onClick={(e) => { setShowPassword(!showPassword); e.target.parentNode.querySelector('input').focus() }} icon={showPassword ? "hide" : "view"} />
          </Container>
          <Errors/>
          <CardActionButton highlight isLoading={isLoading} type="submit">Login</CardActionButton>
          <span style={{color: '#B6B6B6', fontSize: '13px', marginTop: '2rem', display: 'block'}}>
            By continuing, you agree to the Terms of Service and the use of cookies.
          </span>
        </>}</WithForm>
      </SignInCard>
      <NeedSupport>
        <b>Need support? Call {phone} or E-mail <a href={`mailto:${email}`}>{email}</a></b><p></p>            
      </NeedSupport>
    </SignInLayout>
    <FooterContainer
      isMobile={isMobile()}
    >
      <UserlistPurviewFooter />
    </FooterContainer>
  </>
}