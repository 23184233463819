import _ from 'lodash/fp'
import {callIfFunction} from '@startlibs/utils'

export const SERVER_ERROR = 'The communication with the server has failed, please check your connection and try again'

const onEmpty = (success,errors) => Object.keys(errors).length ? errors : success

export const onlyIf = (key,fn,validation) => (s,k,props) => fn(_.get(key,props)) ? validation(s,k,props) : false

export const onlyIfEqual = (key,equal,validation) => onlyIf(key,(v) => v === equal,validation)
export const onlyIfNotEqual = (key,equal,validation) => onlyIf(key,(v) => v !== equal,validation)

export const required = (s, k,props) => !s && 'Required field(s).'
export const booleanRequired = (s, k) => required(s === true || s === false)

export const minLength = (n) => (s, k) => s && s.length < n && `Field must have at least ${n} characters.`

export const confirmPasswords = (passwordKey) => (confirm, k, props) => (props[passwordKey] || confirm) && props[passwordKey] !== confirm && 'Passwords do not match.'
export const confirmEmails = (emailKey) => (confirm, k, props) => (_.get(emailKey,props) || confirm) && _.get(emailKey,props) !== confirm && 'Emails do not match.'

export const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const emailValidation = (s, k) => !emailRegex.test(s) && 'Invalid email address.'

export const userTemplateIsValid = (value=0, options) => {
  return options.find((element) => element == value) >= 0;
} 
export const userTemplateValidation = (value) => { return !userTemplateIsValid(value,[1,2,3,4,5,6]) && 'Please select an user template.'}

export const optional = (cb) => (v,...args) => v ? cb(v,...args) : false

export const buildValidation = (validations,custom = () => {}) => (props) =>
  onEmpty(false,
    {
      ..._.flow(
        _.entries,
        _.map(([k, v]) => [k, [].concat(v).map(fn => callIfFunction(fn,_.get(k, props), k, props)).filter(_ => _)]),
        _.filter(([k, v]) => v.length),
        _.fromPairs
      )(validations),
      ...callIfFunction(custom,props)
    }
  )


export const responseFailure = (fn = _.identity) => (_,response,{setErrors}) => {
  const errorSetter = setErrors
  const r = [].concat(response,_)
  console.log(r)
  const error = fn(...r)
  if (error === true) {
  } else if (error) {
    errorSetter(error)
  } else {
    errorSetter({"":[SERVER_ERROR]})
  }
}
